export class Modal {
  constructor() {
    this.modal = document.getElementById("modal");
    this.modalCloseBtn = document.getElementById("modal-close");
    this.modalBody = document.getElementById("modal-body");
    this.init();
  }

  init() {
    // Bind event listeners
    this.modalCloseBtn.addEventListener("click", () => this.close());

    // Close modal when clicking outside the modal content
    window.addEventListener("click", (event) => {
      if (event.target === this.modal) {
        this.close();
      }
    });
  }

  open(content, setupHandlers = null) {
    this.modalBody.innerHTML = content;
    this.modal.style.display = "block";
    document.body.style.overflow = "hidden"; // Prevent background scrolling

    // After content is set, attach any necessary event listeners within the modal
    this.attachContentEvents();

    // If setupHandlers is provided, call it to set up additional event handlers
    if (setupHandlers) {
      setupHandlers(this.close.bind(this));
    }
  }

  close() {
    this.modal.style.display = "none";
    this.modalBody.innerHTML = "";
    document.body.style.overflow = "auto"; // Restore background scrolling
  }

  attachContentEvents() {
    // Example: Attach event listeners to buttons within the modal
    const okBtn = this.modalBody.querySelector("#modal-ok-btn");
    if (okBtn) {
      okBtn.addEventListener("click", () => this.close());
    }

    const errorOkBtn = this.modalBody.querySelector("#modal-error-ok-btn");
    if (errorOkBtn) {
      errorOkBtn.addEventListener("click", () => this.close());
    }
  }
}

// Content functions

export function getInfoContent() {
  return `
      <h2>Über die Schmerzzeichnungs-App</h2>
      <p>
          Diese Anwendung ermöglicht es Ihnen, Schmerzbereiche auf einem Körperdiagramm zu markieren. Sie können zeichnen, löschen und den primären Schmerzort angeben. Verwenden Sie den Zoom-Schieberegler, um Ihre Ansicht anzupassen, und die Speichern-Taste, um Ihre Anmerkungen zu exportieren.
      </p>
      <h3>Verwendung</h3>
      <ul>
          <li><strong>Zeichnen:</strong> Klicken Sie auf die Zeichnen-Taste, um Schmerzbereiche zu markieren.</li>
          <li><strong>Radierer:</strong> Verwenden Sie den Radierer, um unerwünschte Markierungen zu entfernen.</li>
          <li><strong>Ort:</strong> Geben Sie den primären Schmerzort mit der Ort-Taste an.</li>
          <li><strong>Zoom:</strong> Passen Sie das Zoom-Level mit dem Schieberegler auf der rechten Seite an.</li>
          <li><strong>Speichern:</strong> Speichern Sie Ihre Zeichnung, indem Sie auf die Speichern-Taste klicken.</li>
          <li><strong>Zurücksetzen:</strong> Löschen Sie Ihre Zeichnung, indem Sie auf die Zurücksetzen-Taste klicken.</li>
      </ul>
      <p>
          Halten Sie die Maustaste oder den Finger gedrückt, um die Ansicht zu verschieben. Ein Klick auf die jeweilige Funktion wechselt in den entsprechenden Modus (z. B. Zeichnen). Durch erneutes Klicken deaktivieren Sie den Modus und kehren in den Verschiebemodus zurück.
      </p>
      <p>
          Für weitere Informationen oder Unterstützung wenden Sie sich bitte an unser Team.
      </p>
    `;
}

export function getSaveConfirmationContent() {
  return `
      <h2>Übermittlung erfolgreich</h2>
      <p>Ihr Schmerzzeichnung wurde erfolgreich an das Spital übermittelt.</p>
      <button id="modal-ok-btn" class="control-btn">OK</button>
    `;
}

export function getRadiationQuestionsContent() {
  const content = `
      <h2>Zieht der Schmerz irgendwohin?</h2>
      <form id="radiation-form">
        <label>
          <input type="radio" name="radiation" value="ja" required />
          Ja
        </label><br/>
        <label>
          <input type="radio" name="radiation" value="nein" />
          Nein
        </label><br/>
        <label>
          <input type="radio" name="radiation" value="kann ich nicht angeben" />
          Kann ich nicht angeben
        </label><br/><br/>
  
        <div id="radiation-location-container" style="display: none;">
          <h3>Wohin zieht der Schmerz?</h3>
          <label>
            <input type="radio" name="radiationLocation" value="zieht bis vorne in die Leiste" />
            Zieht bis vorne in die Leiste
          </label><br/>
          <label>
            <input type="radio" name="radiationLocation" value="in den Unterschenkel" />
            In den Unterschenkel
          </label><br/>
          <label>
            <input type="radio" name="radiationLocation" value="in den Fuss" />
            In den Fuss
          </label><br/><br/>
        </div>
  
        <div id="fuss-detail-container" style="display: none;">
          <h3>Wo genau im Fuss?</h3>
          <label>
            <input type="radio" name="fussDetail" value="innen" />
            Innen
          </label><br/>
          <label>
            <input type="radio" name="fussDetail" value="aussen" />
            Aussen
          </label><br/>
          <label>
            <input type="radio" name="fussDetail" value="oben" />
            Oben
          </label><br/><br/>
        </div>
  
        <button type="submit" class="control-btn">Übermitteln</button>
        <button type="button" id="radiation-cancel-btn" class="control-btn">Abbrechen</button>
      </form>
    `;

  /**
   * Sets up event handlers for the radiation questions form.
   * Shows or hides sections based on user selections.
   */
  function setupRadiationQuestionsHandlers(closeHandle = null) {
    const radiationForm = document.getElementById("radiation-form");
    if (!radiationForm) {
      console.error("Radiation form not found in the DOM.");
      return;
    }

    const radiationRadios = radiationForm.elements["radiation"];
    const radiationLocationContainer = document.getElementById(
      "radiation-location-container"
    );
    const radiationLocationRadios = radiationForm.elements["radiationLocation"];
    const fussDetailContainer = document.getElementById(
      "fuss-detail-container"
    );
    const fussDetailRadios = radiationForm.elements["fussDetail"];
    const cancelButton = document.getElementById("radiation-cancel-btn");

    if (!radiationLocationContainer || !fussDetailContainer || !cancelButton) {
      console.error("One or more containers/buttons not found in the DOM.");
      return;
    }

    // Function to handle changes in the "radiation" radio buttons
    function handleRadiationChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue === "ja") {
        radiationLocationContainer.style.display = "block";
      } else {
        radiationLocationContainer.style.display = "none";
        fussDetailContainer.style.display = "none";
        // Reset radiationLocation and fussDetail selections
        resetRadioGroup(radiationLocationRadios);
        resetRadioGroup(fussDetailRadios);
      }
    }

    // Function to handle changes in the "radiationLocation" radio buttons
    function handleRadiationLocationChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue === "in den Fuss") {
        fussDetailContainer.style.display = "block";
      } else {
        fussDetailContainer.style.display = "none";
        // Reset fussDetail selections
        resetRadioGroup(fussDetailRadios);
      }
    }

    // Helper function to reset a group of radio buttons
    function resetRadioGroup(radioGroup) {
      for (let radio of radioGroup) {
        radio.checked = false;
      }
    }

    // Attach event listeners to the "radiation" radio buttons
    for (let radio of radiationRadios) {
      radio.addEventListener("change", handleRadiationChange);
    }

    // Attach event listeners to the "radiationLocation" radio buttons
    for (let radio of radiationLocationRadios) {
      radio.addEventListener("change", handleRadiationLocationChange);
    }

    // Handle the cancel button click
    cancelButton.addEventListener("click", () => {
      radiationForm.reset();
      radiationLocationContainer.style.display = "none";
      fussDetailContainer.style.display = "none";
      if (closeHandle) {
        closeHandle();
      }
    });

    // Optionally, handle form submission
    radiationForm.addEventListener("submit", (event) => {
      event.preventDefault();
      const modal = new Modal();
      modal.close();
      modal.open(getSaveConfirmationContent());
    });
  }

  return {
    content,
    setupRadiationQuestionsHandlers,
  };
}
